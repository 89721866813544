import { Injectable, signal } from '@angular/core';
import { Banner, ShowBannerOptions } from '@memberspot/admin/shared/util/model';
import { nanoid } from 'nanoid';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private _banners = signal<Banner[]>([]);

  getBanners() {
    return this._banners.asReadonly();
  }

  showBanner(banner: ShowBannerOptions): void {
    if (banner.id && this._banners().some((b) => b.id === banner.id)) {
      return;
    }

    const bannerWithId = {
      ...banner,
      id: banner.id || nanoid(),
    };

    this._banners.update((banners) => [...banners, bannerWithId]);
  }

  closeBanner(bannerId: string): void {
    this._banners.update((banners) =>
      banners.filter((banner) => banner.id !== bannerId),
    );
  }
}
